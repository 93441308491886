import React, { useContext, useState } from 'react';
import { CancellationModal as PackageCancellationModal } from 'packages/booking-components';
import { Button, LoadingSpinner } from 'shared-library';
import { RootContext } from '../RootContext';
import {fetchAppsResource, fetchPiiResource} from "packages/apis";

const CancellationModal = () => {
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [removingAppointment, setRemovingAppointment] = useState<boolean>(false);

  const { rootElement } = useContext(RootContext);
  const appointmentTime = rootElement.getAttribute('data-appointmentTime');
  const appointmentType = rootElement.getAttribute('data-appointmentType');
  const prenuvoID = rootElement.getAttribute('data-prenuvoID');
  const studyID = rootElement.getAttribute('data-studyID');
  const location = rootElement.getAttribute('data-location');
  const sfOpportunityId = rootElement.getAttribute('data-sfOpportunityId');

  const handleRemoveAppointment = async (cancellationDetail, cancellationReason) => {
    setRemovingAppointment(true);
    setRemoveModal(false);
    const { parsedBody } = await fetchAppsResource(`/booking/change/remove-appointment`, 'POST', {
      studyID,
    });
    await fetchAppsResource('/calendar/event/delete', 'POST', { ...parsedBody, studyID });
    if (sfOpportunityId) {
      await fetchPiiResource(`/api/v1/salesforce/opportunity/${sfOpportunityId}`, 'PATCH', {
        stage: 'Cancelled',
        cancellationDetail,
        cancellationReason,
      });
    } else if (location?.type !== 'whitelabel') {
      await fetchPiiResource(`/api/v1/salesforce/opportunity/prenuvoID/${prenuvoID}`, 'POST', {
        stage: 'Cancelled',
        cancellationDetail,
        cancellationReason,
      });
    }
    setRemovingAppointment(false);
    // $('#delete-appointment').html('Appointment deleted').css('background-color', '#2EC4B6');
    window.location.reload();
  }
  return (
    <>
      <Button size={'medium'} variant={'primary'} onClick={() => setRemoveModal(true)}>
        {removingAppointment ? <LoadingSpinner size={"small"} /> : 'Cancel Appointment'}
      </Button>
      {removeModal && (
        <PackageCancellationModal
          appointmentType={appointmentType}
          setRemoveModal={setRemoveModal}
          originalAppointmentTime={appointmentTime}
          handleRemoveAppointment={handleRemoveAppointment}
        />
      )}
    </>
  );
};

export default CancellationModal;
